<template>
  <div id="myCarousel" class="carousel slide" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
      <li data-target="#myCarousel" data-slide-to="2"></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner">
      <div class="item active">
        <img
          src="../../assets/Icons/path-17.svg"
          alt=""
          style="
            position: absolute;
            padding-left: 52px;
            padding-top: 27px;
            margin-top: 245px;
          "
        />
        <img
          src="../../assets/tech-life-life-management.png"
          style="
            position: absolute;
            z-index: 3;
            width: 350px;
            height: 350px;
            object-fit: contain;
            margin-top: 232px;
          "
        />

        <div class="carousel-content">
          <h1>
            Focused on results <br />
            not just media metrics.
          </h1>
        </div>

      </div>

      <div class="item">
        <img
          src="../../assets/Icons/combined-shape.svg"
          alt=""
          style="
            position: absolute;
            padding-left: 50px;
            padding-top: 24px;
            margin-top: 232px;
            margin-left: 20px;
            width: 255px;
            height: 250px;
          "
        />
        <img
          src="../../assets/tech-life-blockchain.png"
          style="
            position: absolute;
            z-index: 3;
            width: 350px;
            height: 350px;
            object-fit: contain;
            margin-top: 212px;
          "
        />
        <div class="carousel-content">
          <h1>
            Unified results and <br />
            not siloed.
          </h1>
        </div>
      </div>

      <div class="item">
        <img
          src="../../assets/Icons/path-17.svg"
          alt=""
          style="
            position: absolute;
            padding-left: 38px;
            padding-top: 24px;
            margin-top: 258px;
            width: 255px;
            height: 255px;
            margin-left: 28px;
          "
        />

        <img
          src="../../assets/tech-life-media.png"
          style="
            position: absolute;
            z-index: 3;
            width: 350px;
            height: 350px;
            object-fit: contain;
            margin-top: 232px;
          "
        />

        <div class="carousel-content">
          <h1>
            Dynamic scenario planning <br />
            linked to business KPI
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
};
</script>

<style lang="css" scoped>
.carousel-content {
  position: absolute;
  top: 630px;
  left: -75px;
  width: 452px;
  height: 100px;
  font-family: ProximaNovaBold;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #222a37;
}

.carousel-inner {
  position: relative;
  height: 700px;
}

.carousel-indicators {
  position: absolute;
  top: 739px;
  left: -100px;
}

.carousel-indicators .active {
  background-color: #222a37;
  width: 32px;
  height: 8px;
  object-fit: contain;
  border-radius: 4px;
  margin-left: 6px;
}

.carousel-indicators li {
  height: 8px;
  width: 8px;
  border: 2px;
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
  border: 1px solid #222a37;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
</style>