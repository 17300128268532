<!-- template -->
<template>
  <div id="app">
    <div class="container">
      <div class="row">
        <div class="col-md-6 d-none d-md-block">
          <carousel />

          <h5 class="Copyright-2021-Dat">
            Copyright © 2021 Data Poem All rights reserved. Privacy and Legal
            Policies
          </h5>
        </div>

        <div class="col-md-6 p-0">
          <div class="auth-wrapper py-0 py-md-5">
            <div class="auth-inner">
              <img
                src="../assets/bitmap.png"
                srcset="../assets/bitmap@2x.png 2x, ../assets/bitmap@3x.png 3x"
                class="Bitmap"
              />

              <div class="Forgot-your-password- center-block">
                Reset your password
              </div>

              <b-form @submit.stop.prevent="onSubmit" class="px-5">
                <div class="w-100 d-flex">
                  <b-form-group
                    class="w-100 p-2"
                    id="example-input-group-forgot"
                  >
                    <div class="d-flex w-100 p-0 px-md-4 row m-0">
                      <span
                        class="content-center inputIcon"
                        :class="{
                          invalid: $v.form.password.$error,
                          success:
                            !$v.form.password.$error &&
                            $v.form.password.$model == '',
                        }"
                        ><img
                          src="@/assets/lock-password-line.png"
                          alt="userIcon"
                      /></span>
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.password.$model"
                        :state="validateState('password')"
                        aria-describedby="input-1-live-feedback"
                        placeholder="New password"
                        :type="showPassword ? 'text' : 'password'"
                        style="width: 80%; margin-right: -1px !important"
                        class="c-form-input m-0 pr-0"
                      ></b-form-input>
                      <span
                        class="content-center inputIcon passwordIcon"
                        :class="{
                          invalid: $v.form.password.$error,
                          success:
                            !$v.form.password.$error &&
                            $v.form.password.$model == '',
                        }"
                        ><img
                          v-if="!showPassword"
                          @click="togglePassword"
                          src="../assets/eye-close-line.svg"
                          alt=""
                        />
                        <img
                          v-else
                          @click="togglePassword"
                          src="../assets/eye-line.png"
                          alt=""
                        />
                      </span>
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >Required field min length 6.</b-form-invalid-feedback
                      >
                    </div>
                  </b-form-group>
                </div>
                <div class="w-100 d-flex mt-5">
                  <b-form-group class="w-100 p-2" id="example-input-group-2">
                    <div class="d-flex w-100 p-0 px-md-4 row m-0">
                      <span
                        class="content-center inputIcon"
                        :class="{
                          invalid: $v.form.repeatPassword.$error,
                          success:
                            !$v.form.repeatPassword.$error &&
                            $v.form.repeatPassword.$model == '',
                        }"
                        ><img
                          src="@/assets/lock-password-line.png"
                          alt="userIcon"
                      /></span>
                      <b-form-input
                        id="example-input-2"
                        name="example-input-2"
                        v-model="$v.form.repeatPassword.$model"
                        :state="validateState('repeatPassword')"
                        aria-describedby="input-2-live-feedback"
                        placeholder="Re-enter Password"
                        :type="showResetPassword ? 'text' : 'password'"
                        style="width: 80%; margin-right: -1px !important"
                        class="c-form-input m-0 pr-0"
                      ></b-form-input>
                      <span
                        class="content-center inputIcon passwordIcon"
                        :class="{
                          invalid: $v.form.repeatPassword.$error,
                          success:
                            !$v.form.repeatPassword.$error &&
                            $v.form.repeatPassword.$model == '',
                        }"
                        ><img
                          v-if="!showResetPassword"
                          @click="toggleResetPassword"
                          src="../assets/eye-close-line.svg"
                          alt=""
                        />
                        <img
                          v-else
                          @click="toggleResetPassword"
                          src="../assets/eye-line.png"
                          alt=""
                        />
                      </span>
                      <b-form-invalid-feedback id="input-2-live-feedback"
                        >Should be same as password.</b-form-invalid-feedback
                      >
                    </div>
                  </b-form-group>
                </div>
                <div class="text-right mt-4 px-4">
                  <w-button
                    :class="{ resetPasswordLink: isDisable }"
                    style="width: 45% !important"
                    :buttonLoader="buttonLoader"
                    :buttonText="'Reset'"
                    :isDisable="isDisable"
                    @buttonClicked="onSubmit"
                    class="mr-2"
                  >
                  </w-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import Carousel from "@/components/Login/Carousel.vue";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import Button from "../components/Profile/Button.vue";
import { LoginServices } from "../services/LoginServices.js";
const loginServices = new LoginServices();

export default {
  name: "ResetPassword",

  mixins: [validationMixin, formMixin],

  components: {
    Carousel,
    "w-button": Button,
  },

  data() {
    return {
      form: {
        password: null,
        repeatPassword: null,
      },
      validForm: false,
      showPassword: false,
      showResetPassword: false,
      buttonLoader:'normal'
    };
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },

  watch: {
    "$v.form.$anyError"(val) {
      if (!val) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }
    },
  },
  created() {
    if (
      this.$route.params.email === undefined ||
      this.$route.params.otp === undefined
    ) {
      this.$router.push("/");
    }
  },
  computed: {
    isDisable() {
      if (this.validForm) {
        if (this.form.password === this.form.repeatPassword) {
          if (this.form.password.length >= 6) {
            return false;
          }
          return true;
        }
        return true;
      }
      return true;
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      var data = {
        email: this.$route.params.email,
        otp: this.$route.params.otp,
        password: this.form.repeatPassword,
      };
      this.buttonLoader = 'loader'
      loginServices.newPassword(data).then((res) => {
        console.log(res);
        this.buttonLoader = 'normal'
        this.$router.push("/process-done");
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    toggleResetPassword() {
      this.showResetPassword = !this.showResetPassword;
    },
  },
};
</script>

<style scoped>
.resetPasswordLink.disableButton {
  background-color: rgb(5, 5, 5, 0.2) !important;
  color: #ffffff !important;
}
.inputIcon.invalid {
  color: #f8d8d8 !important;
  background-color: #f8d8d8 !important;
  border: 1px solid #dc3545;
  border-right: 0px;
}
.inputIcon.invalid.passwordIcon {
  border-right: 1px solid #dc3545;
}
.inputIcon.passwordIcon {
  border-right: 1px solid;
  cursor: pointer;
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 3px;
}
.inputIcon.success {
  font-family: ProximaNovaBold;
  color: #3a424f;
}
.inputIcon {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #a3b6d9;
  background-color: #eff5ff;
  border: 1px solid;
  border-right: 0px;
  height: 60px;
  width: 10%;
  float: left;
}
* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

body,
html,
#app,
#root,
.auth-wrapper {
  width: 100%;
  height: auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 546px;
  height: 860px;
  margin-right: 50px;
  padding: 0;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: #ffffff;
  position: relative;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.Bitmap {
  width: 57px;
  height: 85px;
  top: 150px;
  position: absolute;
  object-fit: contain;
  left: 50%;
  transform: translateX(-50%);
}

.Forgot-your-password- {
  width: 319px;
  height: 50px;
  margin-top: 270px;
  margin-bottom: 53px;
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: var(--darke-text);
}

::placeholder {
  color: #9aafd4;
  opacity: 1; /* Firefox */
}

.password {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 485px;
  right: 450px;
  object-fit: contain;
  background-color: #f2f5fa;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 30px;
}

.btn-effect-valid {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}

.isi {
  width: 190px;
  height: 60px;
  margin: 0 0 0 12px;
  object-fit: contain;
  opacity: 0.5;
}

.Copyright-2021-Dat {
  width: 434px;
  height: 16px;
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  left: -30px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

/* form inputs */
.c-form-input {
  height: 60px !important;
  padding: 18px 18px 18px 6px !important;
  border-radius: 0px !important;
  background-color: #eff5ff !important;
  object-fit: contain;
  background-size: 24px;
  font-size: 16px;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-image: none;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #a3b6d9 !important;
}
.c-form-input.is-invalid {
  background-color: #f8d8d8 !important;
}

.form-control:focus.is-invalid {
  border-color: #dc3545 !important;
}

.eye-line {
  position: absolute;
  top: 380px;
  right: 107px;
  width: 24px;
  height: 24px;
  margin: 0 0 0 416px;
  object-fit: contain;
  color: #9aafd4;
  cursor: pointer;
}

.eye-line:hover {
  color: #3a424f;
}

.eye-line2 {
  position: absolute;
  top: 495px;
  right: 107px;
  width: 24px;
  height: 24px;
  margin: 0 0 0 416px;
  object-fit: contain;
  color: #9aafd4;
  cursor: pointer;
}

.eye-line2:hover {
  color: #3a424f;
}

.invalid-feedback {
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .auth-inner {
    width: 100%;
  }
}
</style>
